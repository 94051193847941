<template>
  <div class="home">
    <section class="hero">
      <div class="hero-text container">
        <h4>Elmtree Archers</h4>
        <hr />
        <h2>
          Welcome
        </h2>
        <hr />
        <div>
          <p>
            Welcome to a friendly group of like minded folk, of all abilities, young and not
so young, who enjoy the ancient past time of shooting with a bow and arrow.
        </p>

        <p>
          As the nights draw in, there will be indoor archery available every 1st and 3rd Wednesday of the month starting 16th October at Chilsworthy Hall from 7pm until the end of March 2025.
However the club field is still open should members wish to shoot.
        </p>

        <p>Elmtree in 1996 was one of the four founder clubs of the Devon/South West
Challenge and have hosted part of it a total of thirteen times over its twenty-six
year existence.</p>
<p>
Members of Elmtree, have in the past,  set out or help set out top class
courses for the NFAS National Championships or the NFAS 3D National
Championships on no less than twelve occasions.</p>
        </div>
        
      </div>
    </section>
  </div>
</template>
  
<script>
// @ is an alias to /src

export default {
  name: "Home",
  components: {},
};
</script>
  
<style lang="scss" scoped>
.hero {
  background-image: url("../assets/ElmLeaves.png");
  box-shadow: inset 0 0 0 100vmax rgba(0, 0, 0, .5);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: relative;
  padding: 140px 0;
  //height: 100vh;

  img {
    //object-fit: fill; //was cover
    height: 100%;
    width: 100%;
  }

  .hero-text {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;

    p {
      padding: 8px;
      font-size: 24px;
    }

    h4 {
      text-transform: uppercase;
      font-size: 22px;
      padding-bottom: 4px;
    }

    h2 {
      font-size: 50px;

      @media (min-width: 550px) {
        font-size: 80px;
      }
    }

    hr:nth-child(2) {
      max-width: 365px;
      margin-bottom: 16px;
    }

    hr:nth-child(4) {
      height: 6px;
      background-color: #fff;
      border: none;
      max-width: 85px;
      margin-top: 16px;
    }
  }
}
</style>
  